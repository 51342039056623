
.freight-detail{
    padding: 0 20px;
    .freight-detail-title{
        padding: 10px 0;
    }
    .top{
        position: relative;
        .btn-back{
            position: absolute;
            top: -12px;
            right: 20px;
        }
    }
    .freight-title {
        font-size: 20px;
        padding-left: 15px;
        line-height: 1;
        margin: 20px 0 30px;
        position: relative;
        display: flex;
        align-items: center;
        /*justify-content: space-between;*/
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
            transform: translateY(-50%);
        }
    }
}
